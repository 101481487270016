import React from 'react'
import styled from 'styled-components'
import { displayFont, textFont } from '../styles/typography'
import { BulletList } from './BulletList'

const Image = styled.img`
  height: auto;
  width: 100%;
`

export const LargeBullet = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.loose};

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    margin-bottom: ${props => props.theme.spacing.ultraLoose};
  }
`

export const LargeBulletH3 = styled.h3`
  ${displayFont('large', 'bold')};
  margin-bottom: ${props => props.theme.spacing.default};
`

export const LargeBulletH4 = styled.h4`
  ${displayFont('small', 'bold')};
  margin-bottom: ${props => props.theme.spacing.tight};
`

export function LargeBulletP({ children }: { children: string }): JSX.Element {
  return <p>{children}</p>
}

export const LargeBulletContent = styled.div`
  margin-left: ${props => props.theme.spacing.default};
  flex-grow: 1;
  ${textFont('bodyLarge')};

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    margin-left: ${props => props.theme.spacing.extraLoose};
  }

  /* When there is a list after a paragraph, add a margin between them */
  p + ${BulletList} {
    margin-top: ${props => props.theme.spacing.tight};
  }

  /* When there is a heading after a list, add a margin between them */
  ${BulletList} + ${LargeBulletH4} {
    margin-top: ${props => props.theme.spacing.loose};
  }
`

const IconContainer = styled.div`
  flex-basis: 46px;
  min-width: 46px;
`

interface LargeBulletImageProps {
  src: string
}

export function LargeBulletImage({ src }: LargeBulletImageProps): JSX.Element {
  return (
    <IconContainer>
      <Image src={src} />
    </IconContainer>
  )
}
