import styled from 'styled-components'
import { textFont } from '../styles/typography'

export const BulletList = styled.ul`
  ${textFont('bodyLarge', 'semiBold')};
`

export const BulletListItem = styled.li`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.snug};

  ::before {
    content: '•';
    width: 9px;
    height: 9px;
    margin-right: ${props => props.theme.spacing.loose};
    color: ${props => props.theme.palette.orange};
  }
`
