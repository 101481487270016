import React, { FC } from 'react'
import styled from 'styled-components'

// -- Components --
import { Button, SectionHeader } from '../components'
import { Title } from '../components/Title'
import * as Hero from '../components/Hero'
import { BubbleImage } from '../components/BubbleImage'
import { Excerpt, ExcerptCopy, ExcerptCopyOnly } from '../components/Excerpt'

// -- Layout --
import { PageLayout } from '../layouts/PageLayout'
import { LargeCallToAction } from '../modules/LargeCallToAction'
import {
  LargeBullet,
  LargeBulletContent,
  LargeBulletH4,
  LargeBulletImage,
  LargeBulletP
} from '../components/LargeBullet'

// -- Images --
import buenoCrewSrc from '../images/partners/circle-partners.jpg'
import partnerWithBuenoSrc from '../images/hero/hero-partners.jpg'
import sectionHeaderIcon1Src from '../images/partners/section-header-icon-1.png'
import partnerCallToActionSrc from '../images/partners/partner-call-to-action.png'

// Icons
import partnersRevenueSrc from '../images/icons/icon-partners-revenue.svg'
import partnersCostsSrc from '../images/icons/icon-partners-costs.svg'
import partnersCollaborateSrc from '../images/icons/icon-partners-collaborate.svg'
import partnersConnectSrc from '../images/icons/icon-partners-connect.svg'

import { theme } from '../theme'
import { displayFont, textFont } from '../styles/typography'
import { Grid } from '../components/Grid'

const LayoutIntro = styled(Grid)`
  margin-top: ${props => props.theme.spacing.extraLoose};
  margin-bottom: 56px;

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
  }
`

const IntroHeading = styled.h2`
  ${displayFont('large', 'bold')};
  grid-column: 1/-1;
  margin-bottom: ${props => props.theme.spacing.default};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    grid-column: 1 / span 6;
  }
`

const IntroSubHeading = styled.h2`
  ${textFont('bodyLarge', 'normal')};
  grid-column: 1/-1;

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    grid-column: 1 / span 6;
  }
`

const StyledCTA = styled(LargeCallToAction)`
  margin-bottom: ${props => props.theme.spacing.ultraLoose};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    margin-bottom: 56px;
  }
`

const Partners: FC = () => {
  return (
    <PageLayout>
      <Title title="Partner with us - Bueno" />

      <Hero.Wrapper src={partnerWithBuenoSrc}>
        <Hero.LayoutContent style={{ paddingBottom: '160px' }}>
          <Hero.LayoutHeading>
            <Hero.Heading>
              Partner with Bueno and Together we can grow
            </Hero.Heading>
          </Hero.LayoutHeading>
        </Hero.LayoutContent>
      </Hero.Wrapper>

      <LayoutIntro>
        <IntroHeading>Why partner with Bueno?</IntroHeading>
        <IntroSubHeading>
          Bueno is an award-winning and industry-leading Smart Building
          Analytics Solution that will help you expand share of wallet with your
          existing customer base, win new customers, and increase revenue.
          Together we can help customers reach sustainability targets and
          achieve operational and energy savings to ultimately realize
          game-changing outcomes.
        </IntroSubHeading>
      </LayoutIntro>

      <div style={{ marginBottom: '56px' }}>
        <BubbleImage
          src={buenoCrewSrc}
          imagePosition="left"
          textPosition="center"
          maxHeight={640}
        >
          <LargeBullet>
            <LargeBulletImage src={partnersRevenueSrc} />
            <LargeBulletContent>
              <LargeBulletH4>Increase revenue opportunities</LargeBulletH4>
              <LargeBulletP>
                When you partner with Bueno, enjoy long-term, recurring revenue
                streams. Appreciate pull-through revenue in your existing lines
                of business when you sell, implement and support Bueno’s Smart
                Building Analytics Solution.
              </LargeBulletP>
            </LargeBulletContent>
          </LargeBullet>

          <LargeBullet>
            <LargeBulletImage src={partnersCostsSrc} />
            <LargeBulletContent>
              <LargeBulletH4>
                End-to-end support to build your internal capability
              </LargeBulletH4>
              <LargeBulletP>
                We are here to arm you with the ongoing tools you need to
                succeed. From inception, we will strategically partner with you
                to identify the capabilities needed to build a Smart Building
                Analytics team. We will work with you to identify talent, train
                your sales team, and support your technical team as you scale
                up. And together with Bueno’s industry experts, manage the
                entire customer lifecycle from marketing and sales,
                implementation, to support.
              </LargeBulletP>
            </LargeBulletContent>
          </LargeBullet>

          <LargeBullet>
            <LargeBulletImage src={partnersCollaborateSrc} />
            <LargeBulletContent>
              <LargeBulletH4>Collaborate to succeed</LargeBulletH4>
              <LargeBulletP>
                Add your expertise, services, and a suite of solutions to
                Bueno’s Smart Building Analytics Solution. Together, we can
                achieve new heights and add value to existing and new customers.
              </LargeBulletP>
            </LargeBulletContent>
          </LargeBullet>

          <LargeBullet>
            <LargeBulletImage src={partnersConnectSrc} />
            <LargeBulletContent>
              <LargeBulletH4>Flexibility to connect</LargeBulletH4>
              <LargeBulletP>
                Our solution is equipped to work at scale, integrating with
                service management systems that are the engine of your business,
                allowing for seamless integration of our insights into your core
                workflows.
              </LargeBulletP>
            </LargeBulletContent>
          </LargeBullet>
        </BubbleImage>
      </div>

      <div style={{ marginBottom: '56px' }}>
        <StyledCTA
          title="Be at the forefront"
          body="Only when we join forces with businesses like yours can we deliver on the promise to help our customers achieve their goals. Our award-winning Smart Building Analytics Solution will elevate your business and your customer’s performance to the next level."
          callToAction={<Button to="/contact">Talk To An Expert</Button>}
          image={partnerCallToActionSrc}
        />
      </div>
    </PageLayout>
  )
}

export default Partners
